import { render, staticRenderFns } from "./sendCardSuccess.vue?vue&type=template&id=191dbe80&scoped=true&"
import script from "./sendCardSuccess.vue?vue&type=script&lang=js&"
export * from "./sendCardSuccess.vue?vue&type=script&lang=js&"
import style0 from "./sendCardSuccess.vue?vue&type=style&index=0&id=191dbe80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191dbe80",
  null
  
)

export default component.exports