<template>
  <div class="sendCardSuccess">
    <div class="succeed-icon">
      <div class="succeed-icon-center">
        <span class="iconfont iconduigou"></span>
      </div>
      <div class="succeedtext">发卡成功</div>
    </div>
    <div class="btn-operation btn-background" @click="$router.replace('/carteamCard')">完成</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.sendCardSuccess {
  height: 100vh;
  background: #fff;
  padding: 0 50px;

  .succeed-icon {
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .succeed-icon-center {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: #fff;
      background: linear-gradient(135deg,#9ED722,#4AB846);
      text-align: center;
      line-height: 50px;

      span {
        font-size: 30px;
      }

      
    }
    .succeedtext {
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 16px;
      }

  }
  .btn-operation {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    color: #000;
    font-size: 16px;
  }

  .btn-background {
    color: #fff;
    background: linear-gradient(135deg,#9ED722,#4AB846);
  }
}
</style>
